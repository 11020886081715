import { Injectable } from '@angular/core';
import { ApiContent, ApiResponse, CategoryCount } from '@app/models/interface';
import { Observable, map } from 'rxjs';
import { HttpUtilsService } from '../http/http-utils.service';

@Injectable({
  providedIn: 'root',
})
export class APIService {
  constructor(private httpUtils: HttpUtilsService) {}

  getAPIs(
    keywords: string,
    category: string,
    tags: string,
    isData: boolean,
    sort: string,
    page: number,
    pageSize: number
  ): Observable<ApiResponse> {
    const params = {
      keywords: keywords || '',
      category: category || '',
      tags: tags || '',
      isData: isData || false,
      sort: sort || 'RELEASE_TIME_ASC',
      page: page || 1,
      pageSize: pageSize || 10,
    };

    return this.httpUtils.get<ApiResponse>('/apis', params);
  }

  getAPI(apiIdentity: string): Observable<ApiContent> {
    return this.httpUtils.get<ApiContent>(`/apis/${apiIdentity}`);
  }

  TITLE_MAP: { [key: string]: string } = {
    imagerecognition: 'Computer Vision',
    websitetools: 'Website Tools',
    qrcode_barcode: 'QRcode & Barcode',
  };

  getCategories(
    keywords: string,
    category: string,
    tags: string,
    isData: boolean
  ): Observable<CategoryCount[]> {
    const params = {
      keywords: keywords || '',
      category: category || '',
      tags: tags || '',
      isData: isData || false,
    };
    return this.httpUtils
      .get<CategoryCount[]>('/apis/enums/categories', params)
      .pipe(
        map((categories) =>
          categories
            .map((category) => ({
              ...category,
              title: this.TITLE_MAP[category.name],
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
        )
      );
  }

  getTags(
    keywords: string,
    category: string,
    tags: string,
    isData: boolean
  ): Observable<CategoryCount[]> {
    const params = {
      keywords: keywords || '',
      category: category || '',
      tags: tags || '',
      isData: isData || false,
    };
    return this.httpUtils
      .get<CategoryCount[]>('/apis/enums/tags', params)
      .pipe(map((tags) => tags.sort((a, b) => a.name.localeCompare(b.name))));
  }
}
